@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500&display=swap');

#root {
  min-height: 100%;
}

html {
  font-family: -apple-system, BlinkMacSystemFont, 'Roboto',
    'Oxygen','Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html, body {
  height: 100%;
}

[hidden] {
  display: none !important;
}

.input-secundario label {
  font-size: 1rem;
}

.op-5 {
  opacity: 0.5;
}

@media screen and (max-width: 420px) {
  html {
    font-size: 4vmin;
  }
}
